import { getEnvConfig } from "@/configs/UrlConfig";
import { AuthenticatedService } from "../auth/AuthenticatedService";
import { getResponse } from "../utils";

import { Bank } from './types'

import { Response } from "../types";

export class BankService extends AuthenticatedService {
    async getBanks(): Promise<Response<Bank[]>>{
        const url = `${getEnvConfig().apiURL}/admin/banks`;

        return getResponse(url, this.token, 'GET');
    }
}

export default BankService;