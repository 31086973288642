import { ref } from "vue";
import { defineStore } from "pinia";
import { Bank } from "@/services/banks/types";
import { Response } from "@/services/types";
import BankService from "@/services/banks/bankService";

export const useBankStore = defineStore("bankStore", () => {
  const banks = ref<Bank[]>();
  const bankListable = ref<object[]>();

  const bankService = new BankService();
  const bankResponse = ref<Response<Bank[]>>();

  const setBanks = (newBanks: Bank[]) => {
    banks.value = newBanks;
  };

  const fetchBanks = async () => {
    bankResponse.value = await bankService.getBanks();

    if (bankResponse?.value.success && bankResponse.value.data) {
      banks.value = [...bankResponse.value.data];
    }

    // This maps the bank list to a listable, i mean, an object array with key and value
    if (banks.value && banks.value?.length > 0) {
      bankListable.value = [
        ...banks.value.map((bank) => {
          return {
            key: bank.code,
            value: bank.name,
          };
        }),
      ];
    }
  };

  return {
    banks,
    bankListable,

    fetchBanks,
    bankResponse,
    setBanks,
  };
});

export default useBankStore;
