import {
  DividendClass,
  DividendUpdateType,
  PaymentStatus,
  MultiplePaymentsData,
  DividendsCompleteData,
  DividendCreateClass,
  DividendType
} from "./types";
import { AuthenticatedService } from "../auth/AuthenticatedService";
import { getEnvConfig } from "@/configs/UrlConfig";
import { getResponse, keysToSnake, fetchDownloadFile, getResponsePagination  } from "../utils";
import { Response, ResponsePagination } from "../types";

export class DividendService extends AuthenticatedService {
  async createNewDivident(
    dividendPostData: DividendCreateClass
  ): Promise<Response<DividendClass>> {
    const url = `${getEnvConfig().apiURL}/dividends/create`;
    const bodyDividendPostData = keysToSnake(dividendPostData);
    console.log(bodyDividendPostData)
    return getResponse( 
      url,
      this.token,
      "POST",
      bodyDividendPostData,
      undefined,
      DividendClass
    );
  }
  async deleteDividend(
    dividendId: number,
  ): Promise<Response<DividendClass>> {
    const url = `${getEnvConfig().apiURL}/dividends/delete/${dividendId}`;

    return getResponse(
      url,
      this.token,
      "DELETE",
      undefined,
      undefined,
      DividendClass
    );
  }

  async editDividend(
    dividendId: number,
    data: DividendUpdateType,
  ): Promise<Response<DividendClass>>{
    const url = `${getEnvConfig().apiURL}/dividends/${dividendId}/edit`;

    return getResponse(
      url,
      this.token,
      "PUT",
      data,
      undefined,
      DividendClass
    );
  }
  async editMultiplePayment(
    dividendPostData: MultiplePaymentsData
  ): Promise<Response<DividendClass>> {
    const url = `${getEnvConfig().apiURL}/dividends/payments/modify-multiple`;
    const bodyDividendPostData = keysToSnake(dividendPostData);
    console.log({ url, bodyDividendPostData });
    return getResponse(
      url,
      this.token,
      "POST",
      bodyDividendPostData,
      undefined,
      DividendClass
    );
  }
  async completePayment(
    paymentPostData: DividendsCompleteData
  ): Promise<Response<DividendClass>> {
    const url = `${getEnvConfig().apiURL}/dividends/payments/complete`;
    const bodyDividendCompleteData = keysToSnake(paymentPostData);
    return getResponse(
      url,
      this.token,
      "POST",
      bodyDividendCompleteData,
      undefined,
      DividendClass
    );
  }
  async getAllDividends(page = 0): Promise<ResponsePagination<DividendType[]>> {
    let url = `${getEnvConfig().apiURL}/dividends/all`;
    if(page > 0){
      url += `?page=${page}`;
    }

    return getResponsePagination(
      url,
      this.token,
      "GET",
      undefined,
      undefined,
      DividendClass
    );
  }
  async getDividendPayments(id:number): Promise<ResponsePagination<PaymentStatus[]>> {
    const url = `${getEnvConfig().apiURL}/dividends/payments/all?dividend_id=${id}&without_pages=1`;
    
    return getResponse(url, this.token, "GET", undefined, undefined);
  }
  async getShareholderDividends(
    shareholderId: number
  ): Promise<Response<PaymentStatus[]>> {
    const body = {
      shareholderId,
    };
    const url = `${getEnvConfig().apiURL}/dividends/payments/all-user`;

    return getResponse(url, this.token, "POST", keysToSnake(body), undefined);
  }

  async downloadDividendsList(
    ): Promise<void> {
    const url = `${getEnvConfig().apiURL}/dividends/all/list/excel`;

    return fetchDownloadFile(url, "dividendos.xlsx");
  }

  async downloadDividendsPaymentsList(shareholderId: number
    ): Promise<void> {
    const url = `${getEnvConfig().apiURL}/dividends/payments/all-user/excel?shareholder_id=${shareholderId}`;

    return fetchDownloadFile(url, "dividendos-pagos.xlsx");
  }
}
