import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextField = _resolveComponent("TextField")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_TextField, {
      type: "text",
      name: "Título",
      label: 'Título',
      "hide-details": "",
      model: _ctx.title,
      "onUpdate:model": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.title) = $event)),
      hasError: _ctx.titleFieldHasError
    }, null, 8, ["model", "hasError"]),
    _createVNode(_component_TextField, {
      type: "textarea",
      name: "description",
      label: 'Descripción',
      "hide-details": "",
      model: _ctx.description,
      "onUpdate:model": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.description) = $event)),
      hasError: _ctx.descriptionFieldHasError
    }, null, 8, ["model", "hasError"]),
    _createElementVNode("div", null, [
      _createElementVNode("button", {
        class: "success btn ml-auto my-5",
        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleSubmit && _ctx.handleSubmit(...args))),
        disabled: _ctx.disableButton || _ctx.formHasError
      }, " Registrar ", 8, _hoisted_1)
    ])
  ], 64))
}