export enum formOptionsType {
  filterForm,
  newDividendForm,
}
export enum DividendLabelOptionType {
  UF_PER_SHARE = "UF por acción",
  PROPERTY_SALE = "Valor total ",
}
export enum DividendLabelOptionTypeForm {
  UF_PER_SHARE = "UF_PER_SHARE",
  PROPERTY_SALE = "PROPERTY_SALE",
}
export enum DividendRatioOptions {
  "default" = "Por defecto",
  "equalPart" = "Por partes iguales",
  "custom" = "Personalizado",
}
export enum DividendOptionType {
  UF_SHARE = "UF_PER_SHARE",
  PROPERTY_SALE = "PROPERTY_SALE",
}
export enum DividendStatusType {
  PENDING = "PENDING",
  PAID = "PAID",
  REJECTED = "REJECTED"
}
export enum newDividendOptionsEnum {
  paymetOption,
  totalValueOption,
}
export type DividendType = {
  code: string;
  divType: DividendOptionType;
  divTypeString: string;
  ufPerShare: number | string;
  readonly totalUf: number;
  readonly totalShares: number;
  readonly ufPerShareGroupA: number;
  readonly ufPerShareGroupB: number;
  readonly totalClp: number;
  readonly groupAShareholders: number;
  readonly groupBShareholders: number;
  readonly totalShareholders: number;
  readonly id: number;
  distributionDate: Date | string;
  cutoffDate: Date | string;
  totalPayedClp: number | string;
};

export type PaymentStatus = {
  readonly dividendCode: string;
  readonly dividendBalanceUf: number;
  readonly dividendBalanceClp: number;
  readonly shareGroup: string;
  readonly totalUf: number;
  readonly totalClp: number;
  readonly totalShares: number;
  readonly shareholderId: number;
  readonly dividendId: number;
  readonly bankName: string;
  readonly accountType: string;
  readonly accountNumber: string;
  readonly accountOwnerName: string;
  readonly accountOwnerRut: string;
  readonly accountEmail: string;
  readonly id: number;
  readonly status: DividendStatusType;
  readonly comment: string;
  readonly pendingDate: string;
  readonly completionDate: string;
  readonly completionStatus: string;
  readonly investorNumberB:number;
  readonly investorNumberA:number;
  readonly nominalProfitability:number;
  readonly realProfitability:number;
};

export type DividendCreateType = {
  readonly codeType: string | undefined;
  readonly divType: DividendOptionType;
  readonly ufPerShare: number | undefined;
  readonly totalUf: number | undefined;
  readonly distributionDate: string | undefined ;
  readonly cutoffDate: string | undefined ;
  readonly groupARatio: number | undefined;
};

export type DividendUpdateType = {
  code: string | undefined;
  divType: DividendOptionType | undefined;
  ufPerShare: number | undefined;
  totalUf: number | undefined;
  distributionDate: string | undefined ;
  cutoffDate: string | undefined ;
  groupARatio: number | undefined;
};
export class DividendClass {
  constructor(readonly dividensData: DividendType) { }
}
export class DividendsFilter {
  constructor(
    public showPayed: boolean = true,
    public showPending: boolean = true,
    public shareholderId: number | undefined = undefined,
    public dividendId: number | undefined = undefined,
    public dateRange: Date[] | undefined = undefined,
    public filterDate: Date | undefined = undefined,
    public showRejected: boolean = true,
  ) { }
}
export class DividendsCompleteData {
  constructor(
    public paymentId: number | undefined = undefined,
    public status: string | undefined = undefined,
    public comment: string | undefined = undefined,
    public sendEmail: boolean | undefined = undefined
  ) { }
}
export class MultiplePaymentsData {
  constructor(
    public paymentsList: number[] = [],
    public paymentsStatus: DividendStatusType | undefined = undefined,
  ) {}
}

export class DividendCreateClass {
  constructor(
    readonly code: DividendCreateType["codeType"],
    readonly divType: DividendCreateType["divType"],
    readonly ufPerShare: DividendCreateType["ufPerShare"] = undefined,
    readonly totalUf: DividendCreateType["totalUf"] = undefined,
    readonly distributionDate: DividendCreateType["distributionDate"] = undefined,
    readonly cutoffDate: DividendCreateType["cutoffDate"] = undefined,
    readonly groupARatio: DividendCreateType["groupARatio"] = undefined
  ) { }
}
