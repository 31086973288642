import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-753e1b54"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextField = _resolveComponent("TextField")!
  const _component_NewModal = _resolveComponent("NewModal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_NewModal, {
      title: "Cambiar contraseña ",
      visible: _ctx.visible,
      "onUpdate:visible": _cache[3] || (_cache[3] = (e) => _ctx.$emit('update:visible', e))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_TextField, {
          class: "mt-2",
          name: "newPassword",
          model: _ctx.oldPassword,
          "onUpdate:model": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.oldPassword) = $event)),
          type: "password",
          label: "Contraseña actual"
        }, null, 8, ["model"]),
        _createVNode(_component_TextField, {
          class: "mt-2",
          name: "newPassword",
          model: _ctx.newPassword,
          "onUpdate:model": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.newPassword) = $event)),
          type: "password",
          label: "Nueva contraseña"
        }, null, 8, ["model"])
      ]),
      footer: _withCtx(() => [
        _createElementVNode("button", {
          class: "btn",
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.submitForm && _ctx.submitForm(...args)))
        }, " Guardar ")
      ]),
      _: 1
    }, 8, ["visible"])
  ]))
}